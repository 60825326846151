import "./ComingSoon.css"
import React from 'react'

function ComingSoon (){


  return (
    <>
        <div className='comingSoon'>
          <div>
            <div className="imgTop">
              <img className="img1" src={require("./images/nifstar.png")}></img>
            </div>
            <div>
              <div className="logo">
                <img className="img2" src={require("./images/nifty-logo-white.png")}></img>
              </div>
              <div className='mainMsg'>
                <div className='msg1'><h1>Coming Soon!</h1></div>
                <div className='msg2'>
                  <div className='sub'><h2>Are you ready?</h2></div>
                  <div className='links'>
                    <div>
                      <button ><a href={"https://www.instagram.com/niftycreatures/"}><img src={require("./images/instagram.jpg")}></img><span>instagram</span></a></button>
                      <button ><a href={"https://twitter.com/niftycreatures?s=11"}><img src={require("./images/twitter.png")}></img><span>twitter</span></a></button>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>



        </div>
        <div className="bottomHalf">
            <div className="imgBottom">

              {/* <img className="img3" src={require("./images/nif3.png")}></img>
              <img className="img4" src={require("./images/nif4.png")}></img> */}

            </div>
        </div>
    </>

  )
}

export default ComingSoon
