import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ComingSoon from './components/ComingSoon'

function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
            <Route path="/" exact={true} element={<ComingSoon/>} >

            </Route>
        </Routes>
    </BrowserRouter>
    </>


  );
}

export default App;
